import { gql } from "@apollo/client";

export const INTEGRATE_FACEBOOK_USER = gql`
	mutation IntegrateFacebookUser($uat: String!, $facebookUserId: String!) {
		integrateFacebookUser(uat: $uat, facebookUserId: $facebookUserId) {
			email
			facebookIntegration {
				facebookUserId
				pages {
					pageId
					name
					isSubscribed
				}
			}
		}
	}
`;

export const INTEGRATE_TELEGRAM_BOT = gql`
	mutation IntegrateTelegramBot($name: String!, $token: String!) {
		integrateTelegramBot(name: $name, token: $token) {
			telegramIntegration {
				bots {
					botId
					isSubscribed
					name
				}
			}
		}
	}
`;

export const USER = gql`
	query User {
		user {
			email
			facebookIntegration {
				facebookUserId
				pages {
					pageId
					name
					isSubscribed
				}
			}
			telegramIntegration {
				bots {
					botId
					name
					token
					isSubscribed
				}
			}
		}
	}
`;

export const FACEBOOK_INTEGRATION = gql`
	query FacebookIntegration {
		user {
			email
			facebookIntegration {
				facebookUserId
				pages {
					pageId
					name
					isSubscribed
				}
			}
		}
	}
`;

export const TELEGRAM_INTEGRATION = gql`
	query TelegramIntegration {
		user {
			email
			telegramIntegration {
				bots {
					botId
					name
					token
					isSubscribed
				}
			}
		}
	}
`;

export const ME = gql`
	query Me {
		user {
			email
		}
	}
`;

export const CREATE_USER = gql`
	mutation CreateUser($email: String!) {
		createUser(email: $email) {
			email
			facebookIntegration {
				facebookUserId
				pages {
					pageId
					name
					isSubscribed
				}
			}
		}
	}
`;

export const CHANGE_FACEBOOK_PAGE_SUBSCRIPTION = gql`
	mutation ChangeFacebookPageSubscription($pageId: String!, $type: Boolean!) {
		changeFacebookPageSubscriptionToWebhook(pageId: $pageId, type: $type) {
			facebookIntegration {
				facebookUserId
				pages {
					pageId
					name
					isSubscribed
				}
			}
		}
	}
`;

export const CHANGE_TELEGRAM_BOT_SUBSCRIPTION = gql`
	mutation ChangeBotSubscription($botId: String!, $type: Boolean!) {
		changeBotSubscription(botId: $botId, type: $type) {
			telegramIntegration {
				bots {
					botId
					isSubscribed
					name
				}
			}
		}
	}
`;
