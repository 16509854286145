import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyAW_k4ZyK7ORkl3_1LMz3FjQAMoGKYJ5kY",
	authDomain: "b2talk-b58ec.firebaseapp.com",
	projectId: "b2talk-b58ec",
	storageBucket: "b2talk-b58ec.appspot.com",
	messagingSenderId: "589819841992",
	appId: "1:589819841992:web:90eb0326e8928a020fc4fd",
	measurementId: "G-F5RPJNMTRR",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore };
