import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
	Box,
	InputLabel,
	Select,
	// Outli
	Card,
	Chip,
	Table,
	Stack,
	Paper,
	Avatar,
	Button,
	Popover,
	Divider,
	Checkbox,
	FormControl,
	OutlinedInput,
	TableRow,
	MenuItem,
	TextField,
	TableBody,
	Container,
	Typography,
	IconButton,
	TableContainer,
	TablePagination,
} from "@mui/material";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";

import { useQuery, useMutation } from "@apollo/client";
import {
	USER,
	TELEGRAM_INTEGRATION,
	INTEGRATE_TELEGRAM_BOT,
	CHANGE_TELEGRAM_BOT_SUBSCRIPTION,
} from "../apollo/queries/users";
import { COPILOTS, CREATE_COPILOT } from "../apollo/queries/copilots";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	// { id: "id", label: "ID", alignRight: false },
	{ id: "name", label: "Name", alignRight: false },
	{ id: "telegram-bots", label: "Telegram", alignRight: false },
	{ id: "facebook-pages", label: "Facebook", alignRight: false },
	{ id: "1" },
];

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		// backgroundColor: theme.palette.primary.light,
		backgroundColor: theme.palette.divider,
	},
}));

export default function CopilotsPage() {
	const [open, setOpen] = useState(null);

	const [order, setOrder] = useState("asc");

	const [isAddingNewCopilot, setIsAddingNewCopilot] = useState(false);
	const [name, setName] = useState("");
	const [selectedBots, setSelectedBots] = useState([]);
	const [selectedPages, setSelectedPages] = useState([]);

	const [selected, setSelected] = useState([]);

	const [orderBy, setOrderBy] = useState("name");

	const {
		loading: copilotsLoading,
		error: copilotsError,
		data: copilotsData,
		refetch: copilotsRefetch,
	} = useQuery(COPILOTS);

	const {
		loading: usersLoading,
		error: usersError,
		data: usersData,
		refetch: usersRefetch,
	} = useQuery(USER);

	const [
		createCopilot,
		{
			loading: createCopilotLoading,
			error: createCopilotError,
			data: createCopilotData,
		},
	] = useMutation(CREATE_COPILOT);

	const handleOpenMenu = (event) => {
		setOpen(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setOpen(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const createNewCopilotRequestHandler = async ({
		name,
		telegramBotIdList,
		facebookPageIdList,
	}) => {
		try {
			await createCopilot({
				variables: {
					name,
					telegramBots: telegramBotIdList,
					facebookPages: facebookPageIdList,
				},
				refetchQueries: [
					{
						query: COPILOTS,
					},
				],
			});
		} catch (error) {
			console.error("createNewCopilotRequestHandler error", error);
		}
	};

	const onAddingNewCopilotClick = () => {
		setIsAddingNewCopilot(true);
	};

	const onSaveNewCopilotClick = async () => {
		if (!isAddingNewCopilot) return;
		await createNewCopilotRequestHandler({
			name,
			telegramBotIdList: selectedBots.map((b) => b.botId),
			facebookPageIdList: selectedPages.map((p) => p.pageId),
		});
		setIsAddingNewCopilot(false);
		setName("");
		setSelectedBots([]);
		setSelectedPages([]);
	};

	const onCancelAddingNewCopilotClick = () => {
		setIsAddingNewCopilot(false);
		setName("");
		setSelectedBots([]);
		setSelectedPages([]);
	};

	const getCopilotTelegramBots = ({ user, copilot }) => {
		const telegramBotIdList = copilot.telegramBots.map((b) => b.botId);
		const copilotBots = user.telegramIntegration.bots.filter((b) =>
			telegramBotIdList.includes(b.botId)
		);
		return copilotBots;
	};

	const getCopilotFacebookPages = ({ user, copilot }) => {
		const facebookPageIdList = copilot.facebookPages.map((p) => p.pageId);
		const copilotBots = user.facebookIntegration.pages.filter((p) =>
			facebookPageIdList.includes(p.pageId)
		);
		return copilotBots;
	};

	const isLoading = () => copilotsLoading || usersLoading;

	// console.log("copilots", copilotsData);
	// console.log("users", usersData);
	return (
		<>
			{isLoading() && <div>Loading...</div>}
			{!isLoading() && (
				<Container>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						mb={5}
					>
						<Typography variant="h4" gutterBottom>
							Your Copilots
						</Typography>
						<Button
							disabled={isAddingNewCopilot}
							variant="contained"
							onClick={onAddingNewCopilotClick}
							startIcon={<Iconify icon="eva:person-add-outline" />}
						>
							Add new copilot
						</Button>
					</Stack>
					<Card>
						<Scrollbar>
							<TableContainer sx={{ minWidth: 800 }}>
								<Table>
									<UserListHead
										order={order}
										orderBy={orderBy}
										headLabel={TABLE_HEAD}
										rowCount={USERLIST.length}
										numSelected={selected.length}
										onRequestSort={handleRequestSort}
									/>
									<TableBody>
										{isAddingNewCopilot && (
											<TableRow>
												<TableCell sx={{ width: 175 }}>
													<TextField
														id="standard-basic"
														label="name"
														variant="standard"
														value={name}
														onChange={(e) => setName(e.target.value)}
													/>
												</TableCell>
												<TableCell>
													<FormControl sx={{ m: 1, width: 150 }}>
														<InputLabel id="minor-roles-select-label">
															You tg bots
														</InputLabel>
														<Select
															labelId="telegram-bots-select-label"
															id="telegram-bots-roles-select"
															multiple={true}
															value={selectedBots}
															onChange={(event) => {
																const {
																	target: { value },
																} = event;
																setSelectedBots(() => value);
															}}
															input={
																<OutlinedInput
																	id="select-multiple-chip"
																	label="Your tg bots"
																	value=""
																/>
															}
															renderValue={(selected) => (
																<Box
																	sx={{
																		display: "flex",
																		flexWrap: "wrap",
																		gap: 0.5,
																	}}
																>
																	{selected.map((value) => (
																		<Chip
																			key={value.botId}
																			label={value.name}
																		/>
																	))}
																</Box>
															)}
														>
															{usersData.user?.telegramIntegration.bots.map(
																(b) => (
																	<MenuItem key={b.botId} value={b}>
																		{b.name}
																	</MenuItem>
																)
															)}
														</Select>
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl sx={{ m: 1, width: 150 }}>
														<InputLabel id="minor-roles-select-label">
															You fb bots
														</InputLabel>
														<Select
															labelId="facebook-pages-select-label"
															id="facebook-pages-select"
															multiple={true}
															value={selectedPages}
															onChange={(event) => {
																const {
																	target: { value },
																} = event;
																setSelectedPages(() => value);
															}}
															input={
																<OutlinedInput
																	id="select-multiple-chip"
																	label="Your fb pages"
																	value=""
																/>
															}
															renderValue={(selected) => (
																<Box
																	sx={{
																		display: "flex",
																		flexWrap: "wrap",
																		gap: 0.5,
																	}}
																>
																	{selected.map((value) => (
																		<Chip
																			key={value.pageId}
																			label={value.name}
																		/>
																	))}
																</Box>
															)}
														>
															{usersData.user?.facebookIntegration.pages.map(
																(p) => (
																	<MenuItem key={p.pageId} value={p}>
																		{p.name}
																	</MenuItem>
																)
															)}
														</Select>
													</FormControl>
												</TableCell>
												<TableCell align="center">
													<Button
														sx={{ width: 132 }}
														disabled={!name}
														color="primary"
														variant="contained"
														startIcon={<Iconify icon="eva:save-outline" />}
														onClick={() => onSaveNewCopilotClick()}
													>
														Save
													</Button>
												</TableCell>
												<TableCell>
													<IconButton
														size="large"
														color="error"
														onClick={() => onCancelAddingNewCopilotClick()}
													>
														<Iconify icon="eva:close-circle-outline" />
													</IconButton>
												</TableCell>
											</TableRow>
										)}
										{copilotsData.copilots &&
											copilotsData.copilots.map((row) => {
												const {
													id,
													name,
													reservationConfig,
													telegramBots,
													facebookPages,
												} = row;
												const selectedUser = selected.indexOf(name) !== -1;

												return (
													<TableRow
														hover
														key={id}
														tabIndex={-1}
														role="checkbox"
														selected={selectedUser}
													>
														<TableCell
															sx={{ width: 175 }}
															component="th"
															scope="row"
															padding="normal"
														>
															<Stack
																direction="row"
																alignItems="center"
																spacing={2}
															>
																{/* <Avatar alt={name} src={avatarUrl} /> */}
																<Typography variant="subtitle2" noWrap>
																	{name}
																</Typography>
															</Stack>
														</TableCell>

														<TableCell align="left">
															{getCopilotTelegramBots({
																user: usersData.user,
																copilot: row,
															}).map((b) => (
																<Chip
																	key={b.botId}
																	label={b.name}
																	variant="outlined"
																/>
															))}
														</TableCell>

														<TableCell align="left">
															{getCopilotFacebookPages({
																user: usersData.user,
																copilot: row,
															}).map((p) => (
																<Chip
																	key={p.pageId}
																	label={p.name}
																	variant="outlined"
																/>
															))}
														</TableCell>

														<TableCell align="right">
															<IconButton
																size="large"
																color="inherit"
																onClick={handleOpenMenu}
															>
																<Iconify icon={"eva:more-vertical-fill"} />
															</IconButton>
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
							</TableContainer>
						</Scrollbar>
					</Card>
				</Container>
			)}

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: {
						p: 1,
						width: 140,
						"& .MuiMenuItem-root": {
							px: 1,
							typography: "body2",
							borderRadius: 0.75,
						},
					},
				}}
			>
				<MenuItem>
					<Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
					Edit
				</MenuItem>

				<MenuItem sx={{ color: "error.main" }}>
					<Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
					Delete
				</MenuItem>
			</Popover>
		</>
	);
}
