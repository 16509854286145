import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
	Card,
	Table,
	Stack,
	Paper,
	Avatar,
	Button,
	Popover,
	Divider,
	Checkbox,
	TableRow,
	MenuItem,
	TextField,
	TableBody,
	Container,
	Typography,
	IconButton,
	TableContainer,
	TablePagination,
} from "@mui/material";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";

import { useQuery, useMutation } from "@apollo/client";
import {
	TELEGRAM_INTEGRATION,
	INTEGRATE_TELEGRAM_BOT,
	CHANGE_TELEGRAM_BOT_SUBSCRIPTION,
} from "../apollo/queries/users";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	// { id: "id", label: "ID", alignRight: false },
	{ id: "name", label: "Name", alignRight: false },
	{ id: "token", label: "Token", alignRight: false },
	{ id: "" },
	{ id: "1" },
];

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		// backgroundColor: theme.palette.primary.light,
		backgroundColor: theme.palette.divider,
	},
}));

export default function TelegramIntegrationPage() {
	const [open, setOpen] = useState(null);

	const [order, setOrder] = useState("asc");

	const [isAddingNewBot, setIsAddingNewBot] = useState(false);
	const [name, setName] = useState("");
	const [token, setToken] = useState("");

	const [selected, setSelected] = useState([]);

	const [orderBy, setOrderBy] = useState("name");

	const {
		loading: telegramIntegrationLoading,
		error: telegramIntegrationError,
		data: telegramIntegrationData,
		refetch: telegramIntegrationRefetch,
	} = useQuery(TELEGRAM_INTEGRATION);

	const [
		changeTelegramBotSubscription,
		{
			loading: changeTelegramBotSubscriptionLoading,
			error: changeTelegramBotSubscriptionError,
			data: changeTelegramBotSubscriptionData,
		},
	] = useMutation(CHANGE_TELEGRAM_BOT_SUBSCRIPTION);

	const [
		integrateTelegramBot,
		{
			loading: integrateTelegramBotLoading,
			error: integrateTelegramBotError,
			data: integrateTelegramBotData,
		},
	] = useMutation(INTEGRATE_TELEGRAM_BOT);

	const handleOpenMenu = (event) => {
		setOpen(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setOpen(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = USERLIST.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleConnectionClick = async ({ botId, type }) => {
		await changeTelegramBotSubscription({
			variables: {
				botId,
				type,
			},
			refetchQueries: [
				{
					query: TELEGRAM_INTEGRATION,
				},
			],
		});
	};

	const integrateTelegramBotRequestHandler = async ({ token, name }) => {
		try {
			await integrateTelegramBot({
				variables: {
					name,
					token,
				},
				refetchQueries: [
					{
						query: TELEGRAM_INTEGRATION,
					},
				],
			});
		} catch (error) {
			console.error("integrateTelegramBotRequestHandler error", error);
		}
	};

	const onIntegrateNewTelegramBotClick = () => {
		setIsAddingNewBot(true);
	};

	const onSaveNewTelegramBotClick = async () => {
		if (!isAddingNewBot) return;
		await integrateTelegramBotRequestHandler({
			name,
			token,
		});
		setIsAddingNewBot(false);
		setName("");
		setToken("");
	};

	const onCancelAddingNewTelegramBotClick = () => {
		setIsAddingNewBot(false);
		setName("");
		setToken("");
	};

	// console.log("facebookIntegrationData", facebookIntegrationData);
	return (
		<>
			{telegramIntegrationLoading && <div>Loading...</div>}
			{!telegramIntegrationLoading && (
				<Container>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						mb={5}
					>
						<Typography variant="h4" gutterBottom>
							Telegram Integration
						</Typography>
						<Button
							disabled={isAddingNewBot}
							variant="contained"
							onClick={onIntegrateNewTelegramBotClick}
							startIcon={<Iconify icon="eva:person-add-outline" />}
						>
							Add new telegram bot
						</Button>
					</Stack>
					<Card>
						<Scrollbar>
							<TableContainer sx={{ minWidth: 800 }}>
								<Table>
									<UserListHead
										order={order}
										orderBy={orderBy}
										headLabel={TABLE_HEAD}
										rowCount={USERLIST.length}
										numSelected={selected.length}
										onRequestSort={handleRequestSort}
										onSelectAllClick={handleSelectAllClick}
									/>
									<TableBody>
										{isAddingNewBot && (
											<TableRow>
												<TableCell sx={{ width: 175 }}>
													<TextField
														id="standard-basic"
														label="name"
														variant="standard"
														value={name}
														onChange={(e) => setName(e.target.value)}
													/>
												</TableCell>
												<TableCell sx={{ width: 450 }}>
													<TextField
														id="standard-basic"
														sx={{ width: "100%" }}
														label="token"
														variant="standard"
														value={token}
														onChange={(e) => setToken(e.target.value)}
													/>
												</TableCell>
												<TableCell></TableCell>
												<TableCell align="center">
													<Button
														sx={{ width: 132 }}
														disabled={!token || !name}
														color="primary"
														variant="contained"
														startIcon={<Iconify icon="eva:save-outline" />}
														onClick={() => onSaveNewTelegramBotClick()}
													>
														Save
													</Button>
												</TableCell>
												<TableCell>
													<IconButton
														size="large"
														color="error"
														onClick={() => onCancelAddingNewTelegramBotClick()}
													>
														<Iconify icon="eva:close-circle-outline" />
													</IconButton>
												</TableCell>
											</TableRow>
										)}
										{telegramIntegrationData.user.telegramIntegration &&
											telegramIntegrationData.user.telegramIntegration.bots.map(
												(row) => {
													const { botId, name, token, isSubscribed } = row;
													const selectedUser = selected.indexOf(name) !== -1;

													return (
														<TableRow
															hover
															key={botId}
															tabIndex={-1}
															role="checkbox"
															selected={selectedUser}
														>
															{/* <TableCell padding="checkbox">
														<Checkbox
															checked={selectedUser}
															onChange={(event) => handleClick(event, name)}
														/>
													</TableCell> */}

															{/* <TableCell align="left">{botId}</TableCell> */}

															<TableCell
																sx={{ width: 175 }}
																component="th"
																scope="row"
																padding="normal"
															>
																<Stack
																	direction="row"
																	alignItems="center"
																	spacing={2}
																>
																	{/* <Avatar alt={name} src={avatarUrl} /> */}
																	<Typography variant="subtitle2" noWrap>
																		{name}
																	</Typography>
																</Stack>
															</TableCell>
															<TableCell sx={{ width: 450 }} align="left">
																{token}
															</TableCell>

															<TableCell align="left">
																<Label
																	sx={{ width: 130 }}
																	color={(isSubscribed && "success") || "error"}
																>
																	{(isSubscribed && "Connected") ||
																		"Not connected"}
																</Label>
															</TableCell>

															<TableCell align="center">
																{(isSubscribed && (
																	<Button
																		sx={{ width: 132 }}
																		color="error"
																		variant="contained"
																		startIcon={
																			<Iconify icon="eva:close-circle-outline" />
																		}
																		onClick={() =>
																			handleConnectionClick({
																				botId,
																				type: false,
																			})
																		}
																	>
																		Disconnect
																	</Button>
																)) || (
																	<Button
																		sx={{ width: 132 }}
																		color="info"
																		variant="contained"
																		startIcon={
																			<Iconify icon="eva:checkmark-circle-2-outline" />
																		}
																		onClick={() =>
																			handleConnectionClick({
																				botId,
																				type: true,
																			})
																		}
																	>
																		Connect
																	</Button>
																)}
															</TableCell>

															<TableCell align="right">
																<IconButton
																	size="large"
																	color="inherit"
																	onClick={handleOpenMenu}
																>
																	<Iconify icon={"eva:more-vertical-fill"} />
																</IconButton>
															</TableCell>
														</TableRow>
													);
												}
											)}
									</TableBody>
								</Table>
							</TableContainer>
						</Scrollbar>

						{/* <TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={USERLIST.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/> */}
					</Card>
				</Container>
			)}

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: {
						p: 1,
						width: 140,
						"& .MuiMenuItem-root": {
							px: 1,
							typography: "body2",
							borderRadius: 0.75,
						},
					},
				}}
			>
				<MenuItem>
					<Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
					Edit
				</MenuItem>

				<MenuItem sx={{ color: "error.main" }}>
					<Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
					Delete
				</MenuItem>
			</Popover>
		</>
	);
}
