import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Router from "./routes";
import ThemeProvider from "./theme";
import Auth from "./components/auth";
import { initApolloClient } from "./apollo/init-apollo-client";
import { auth } from "./firebase/firebase";
// import Firestore from "./Firestore";

// const auth = getAuth();
function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	// const navigate = useNavigate();

	const apolloClient = initApolloClient();

	useEffect(() => {
		// setLoading(true);
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setUser(user);
			if (user) {
				setIsAuthenticated(true);
			} else {
				setIsAuthenticated(false);
			}
			setLoading(false);
			// if (!user) {
			// 	navigate("/login");
			// }
		});

		return () => unsubscribe();
	}, []);

	if (loading) {
		return <div>Loading firebase user...</div>;
	}

	return (
		<BrowserRouter>
			<ThemeProvider>
				<ApolloProvider client={apolloClient}>
					<div>
						<Router isLoggedIn={isAuthenticated} />
					</div>
				</ApolloProvider>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
