import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
	Card,
	Table,
	Stack,
	Paper,
	Avatar,
	Button,
	Popover,
	Divider,
	Checkbox,
	TableRow,
	MenuItem,
	TextField,
	TableBody,
	Container,
	Typography,
	IconButton,
	TableContainer,
	TablePagination,
} from "@mui/material";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";

import { useQuery, useMutation } from "@apollo/client";
import {
	FACEBOOK_INTEGRATION,
	INTEGRATE_FACEBOOK_USER,
	CHANGE_FACEBOOK_PAGE_SUBSCRIPTION,
} from "../apollo/queries/users";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	{ id: "id", label: "ID", alignRight: false },
	{ id: "name", label: "Name", alignRight: false },
	{ id: "subscription", label: "Connection", alignRight: false },
	{ id: "" },
	{ id: "1" },
];

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		// backgroundColor: theme.palette.primary.light,
		backgroundColor: theme.palette.divider,
	},
}));

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export default function FacebookIntegrationPage() {
	const [open, setOpen] = useState(null);

	const [page, setPage] = useState(0);

	const [order, setOrder] = useState("asc");

	const [selected, setSelected] = useState([]);

	const [orderBy, setOrderBy] = useState("name");

	const [filterName, setFilterName] = useState("");

	const [rowsPerPage, setRowsPerPage] = useState(5);

	const {
		loading: facebookIntegrationLoading,
		error: facebookIntegrationError,
		data: facebookIntegrationData,
		refetch: facebookIntegrationRefetch,
	} = useQuery(FACEBOOK_INTEGRATION);

	const [
		changeFacebookPageSubscription,
		{
			loading: changeFacebookPageSubscriptionLoading,
			error: changeFacebookPageSubscriptionError,
			data: changeFacebookPageSubscriptionData,
		},
	] = useMutation(CHANGE_FACEBOOK_PAGE_SUBSCRIPTION);

	const [
		integrateFacebookUser,
		{
			loading: integrateFacebookUserLoading,
			error: integrateFacebookUserError,
			data: integrateFacebookUserData,
		},
	] = useMutation(INTEGRATE_FACEBOOK_USER);

	const handleOpenMenu = (event) => {
		setOpen(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setOpen(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = USERLIST.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleConnectionClick = async ({ pageId, type }) => {
		await changeFacebookPageSubscription({
			variables: {
				pageId,
				type,
			},
			refetchQueries: [
				{
					query: FACEBOOK_INTEGRATION,
				},
			],
		});
	};

	const integrateUserWithFacebook = async ({ uat, facebookUserId }) => {
		try {
			await integrateFacebookUser({
				variables: {
					uat: uat,
					facebookUserId: facebookUserId,
				},
			});
		} catch (error) {
			console.error("integrateUserWithFacebookerr", error);
		}
	};

	const onIntegrateFacebookUserClick = () => {
		console.log("onIntegrateFacebookUserClick");
		window.FB.login(
			function (response) {
				console.log(response);
				if (response.status === "connected") {
					const { accessToken, userID } = response.authResponse;
					console.log(accessToken, userID);
					integrateUserWithFacebook({
						uat: accessToken,
						facebookUserId: userID,
					});
				}
			},
			{
				// return_scopes: true,
				// enable_profile_selector: true,
				config_id: "947273686467663", // configuration ID goes here
				// auth_type: 'code'   // must be set to 'code' for SUAT
			}
		);
	};

	useEffect(() => {
		console.log("useEffect working...");
		window.fbAsyncInit = () => {
			window.FB.init({
				appId: "229468156431014",
				autoLogAppEvents: true,
				xfbml: true,
				version: "v16.0",
			});
		};
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
	}, []);

	// console.log("facebookIntegrationData", facebookIntegrationData);
	return (
		<>
			{facebookIntegrationLoading && <div>Loading...</div>}
			{!facebookIntegrationLoading && (
				<Container>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						mb={5}
					>
						<Typography variant="h4" gutterBottom>
							Facebook Integration
						</Typography>
						{!facebookIntegrationData.user.facebookIntegration && (
							<Button
								variant="contained"
								onClick={onIntegrateFacebookUserClick}
								startIcon={<Iconify icon="eva:person-add-outline" />}
							>
								Integrate facebook account
							</Button>
						)}
						{facebookIntegrationData.user.facebookIntegration && (
							<Button
								variant="contained"
								onClick={onIntegrateFacebookUserClick}
								startIcon={<Iconify icon="eva:plus-fill" />}
							>
								Add more facebook pages
							</Button>
						)}
					</Stack>

					<Card>
						{/* <UserListToolbar
							numSelected={selected.length}
							filterName={filterName}
							onFilterName={handleFilterByName}
						/> */}

						<Scrollbar>
							<TableContainer sx={{ minWidth: 800 }}>
								<Table>
									<UserListHead
										order={order}
										orderBy={orderBy}
										headLabel={TABLE_HEAD}
										rowCount={USERLIST.length}
										numSelected={selected.length}
										onRequestSort={handleRequestSort}
										onSelectAllClick={handleSelectAllClick}
									/>
									<TableBody>
										{facebookIntegrationData.user.facebookIntegration && true}
										{/* facebook account divider */}
										<TableRow hover tabIndex={-1} selected={true}>
											<StyledTableCell padding="normal"></StyledTableCell>
											<StyledTableCell
												component="th"
												scope="row"
												padding="normal"
											>
												<Stack direction="row" alignItems="center" spacing={2}>
													{/* <Avatar alt={name} src={avatarUrl} /> */}
													<Typography variant="subtitle2" noWrap>
														{facebookIntegrationData.user.facebookIntegration
															? facebookIntegrationData.user.facebookIntegration
																	.facebookUserId
															: "You haven't integrated any facebook accounts yet."}
													</Typography>
												</Stack>
											</StyledTableCell>
											<StyledTableCell padding="checkbox"></StyledTableCell>
											<StyledTableCell padding="checkbox"></StyledTableCell>
											<StyledTableCell></StyledTableCell>
										</TableRow>
										{facebookIntegrationData.user.facebookIntegration &&
											facebookIntegrationData.user.facebookIntegration.pages.map(
												(row) => {
													const {
														pageId,
														name,
														// role,
														// status,
														// company,
														// avatarUrl,
														isSubscribed,
														// isVerified,
													} = row;
													const selectedUser = selected.indexOf(name) !== -1;

													return (
														<TableRow
															hover
															key={pageId}
															tabIndex={-1}
															role="checkbox"
															selected={selectedUser}
														>
															{/* <TableCell padding="checkbox">
														<Checkbox
															checked={selectedUser}
															onChange={(event) => handleClick(event, name)}
														/>
													</TableCell> */}

															<TableCell align="left">{pageId}</TableCell>

															<TableCell
																component="th"
																scope="row"
																padding="normal"
															>
																<Stack
																	direction="row"
																	alignItems="center"
																	spacing={2}
																>
																	{/* <Avatar alt={name} src={avatarUrl} /> */}
																	<Typography variant="subtitle2" noWrap>
																		{name}
																	</Typography>
																</Stack>
															</TableCell>

															{/* <TableCell align="left">
														<TextField
															id="standard-basic"
															label="Standard"
															variant="standard"
														/>
													</TableCell> */}

															{/* <TableCell align="left">{role}</TableCell> */}

															{/* <TableCell align="left">
														{isVerified ? "Yes" : "No"}
													</TableCell> */}

															<TableCell align="left">
																<Label
																	sx={{ width: 130 }}
																	color={(isSubscribed && "success") || "error"}
																>
																	{(isSubscribed && "Connected") ||
																		"Not connected"}
																</Label>
															</TableCell>

															<TableCell align="center">
																{(isSubscribed && (
																	<Button
																		sx={{ width: 132 }}
																		color="error"
																		variant="contained"
																		startIcon={
																			<Iconify icon="eva:close-circle-outline" />
																		}
																		onClick={() =>
																			handleConnectionClick({
																				pageId,
																				type: false,
																			})
																		}
																	>
																		Disconnect
																	</Button>
																)) || (
																	<Button
																		sx={{ width: 132 }}
																		color="info"
																		variant="contained"
																		startIcon={
																			<Iconify icon="eva:checkmark-circle-2-outline" />
																		}
																		onClick={() =>
																			handleConnectionClick({
																				pageId,
																				type: true,
																			})
																		}
																	>
																		Connect
																	</Button>
																)}
															</TableCell>

															<TableCell align="right">
																<IconButton
																	size="large"
																	color="inherit"
																	onClick={handleOpenMenu}
																>
																	<Iconify icon={"eva:more-vertical-fill"} />
																</IconButton>
															</TableCell>
														</TableRow>
													);
												}
											)}
									</TableBody>
								</Table>
							</TableContainer>
						</Scrollbar>

						{/* <TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={USERLIST.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/> */}
					</Card>
				</Container>
			)}

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: {
						p: 1,
						width: 140,
						"& .MuiMenuItem-root": {
							px: 1,
							typography: "body2",
							borderRadius: 0.75,
						},
					},
				}}
			>
				<MenuItem>
					<Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
					Edit
				</MenuItem>

				<MenuItem sx={{ color: "error.main" }}>
					<Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
					Delete
				</MenuItem>
			</Popover>
		</>
	);
}
