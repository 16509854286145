import {
	ApolloClient,
	InMemoryCache,
	createHttpLink,
	defaultDataIdFromObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getAuth } from "firebase/auth";

import config from "../config.json";

export const initApolloClient = () => {
	const httpLink = createHttpLink({
		uri: config.graphqlUrl,
		options: {
			reconnect: true,
		},
	});

	const authLink = setContext(async (_, { headers }) => {
		const token = await getAuth().currentUser?.getIdToken();
		// return the headers to the context so httpLink can read them
		// const kek = await getAuth().currentUser?.getIdTokenResult();
		// alert(new Date(kek.expirationTime));
		return {
			headers: {
				...headers,
				firebasetoken: token,
			},
		};
	});

	return new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache({
			dataIdFromObject(responseObject) {
				switch (responseObject.__typename) {
					case "Coordinate":
						return `Coordinate:${responseObject.trackId}`;
					default:
						return defaultDataIdFromObject(responseObject);
				}
			},
		}),
	});
};

export default initApolloClient;
