import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
	Stack,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useMutation } from "@apollo/client";
import { createUserWithEmailAndPassword } from "firebase/auth";
// components
import Iconify from "../../../components/iconify";

import { auth } from "../../../firebase";
import { CREATE_USER } from "../../../apollo/queries/users";

// ----------------------------------------------------------------------

export default function SignUpForm() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const [
		createUser,
		{
			loading: createUserLoading,
			error: createUserError,
			data: createUserData,
		},
	] = useMutation(CREATE_USER);

	const [showPassword, setShowPassword] = useState(false);

	const signUp = async () => {
		try {
			const result = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			// const token =
			// console.log("sign up result", result?.user?.accessToken);
			const user = await createUser({
				variables: {
					email,
				},
			}).catch((err) => console.error("catch", err));
			navigate("/", { replace: true });
		} catch (error) {
			console.error("erer", error);
		}
	};

	return (
		<>
			<Stack spacing={3}>
				<TextField value={email} onChange={(e) => setEmail(e.target.value)} />

				<TextField
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					type={showPassword ? "text" : "password"}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									edge="end"
								>
									<Iconify
										icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ my: 2 }}
			>
				{/* <Link variant="subtitle2" underline="hover">
					Forgot password?
				</Link> */}
			</Stack>

			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				onClick={signUp}
			>
				Sign Up
			</LoadingButton>
		</>
	);
}
