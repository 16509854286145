import { gql } from "@apollo/client";

export const COPILOTS = gql`
	query Copilots {
		copilots {
			id
			telegramBots {
				botId
			}
			reservationConfig {
				start_serve
				finish_serve
				default_reservation_length
			}
			name
			facebookPages {
				pageId
			}
		}
	}
`;

export const CREATE_COPILOT = gql`
	mutation CreateCopilot(
		$name: String!
		$facebookPages: [String!]
		$telegramBots: [String!]
	) {
		createCopilot(
			name: $name
			facebookPages: $facebookPages
			telegramBots: $telegramBots
		) {
			id
			telegramBots {
				botId
			}
			reservationConfig {
				start_serve
				default_reservation_length
				finish_serve
			}
			name
			facebookPages {
				pageId
			}
		}
	}
`;
