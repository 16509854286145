import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import Page404 from "./pages/Page404";
import FacebookIntegrationPage from "./pages/FacebookIntegrationPage";
import TelegramIntegrationPage from "./pages/TelegramIntegrationPage";
import CopilotsPage from "./pages/CopilotsPage";
import DashboardAppPage from "./pages/DashboardAppPage";

export default function Router({ isLoggedIn }) {
	// alert(isLoggedIn);
	const routes = useRoutes([
		{
			path: "/",
			element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
			children: [
				{ element: <Navigate to="/app" />, index: true },
				{ path: "app", element: <DashboardAppPage /> },
				{ path: "copilots", element: <CopilotsPage /> },
				{ path: "facebook-integration", element: <FacebookIntegrationPage /> },
				{ path: "telegram-integration", element: <TelegramIntegrationPage /> },
				// { path: "products", element: <FacebookIntegrationPage /> },
				// { path: 'blog', element: <BlogPage /> },
			],
		},
		{
			path: "/login",
			element: <LoginPage />,
		},
		{
			path: "/sign-up",
			element: <SignUpPage />,
		},
		// {
		// 	element: <SimpleLayout />,
		// 	children: [
		// 		{ element: <Navigate to="/dashboard/app" />, index: true },
		// 		{ path: "404", element: <Page404 /> },
		// 		{ path: "*", element: <Navigate to="/404" /> },
		// 	],
		// },
		// {
		// 	path: "*",
		// 	element: <Navigate to="/404" replace />,
		// },
	]);

	return routes;
}
