import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { useQuery, useMutation } from "@apollo/client";
import { Grid, Container, Typography } from "@mui/material";

export default function DashboardAppPage() {
	const theme = useTheme();

	return (
		<>
			<Container maxWidth="xl">
				<Typography variant="h4" sx={{ mb: 5 }}>
					Hi, Welcome back
				</Typography>

				<Grid container spacing={3}></Grid>

				{/* <LoadingButton
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					onClick={integrateUserWithFacebook}
				>
					Integrate User with Facebook
				</LoadingButton> */}
			</Container>
		</>
	);
}
