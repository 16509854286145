import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
	Box,
	Link,
	Stack,
	IconButton,
	InputAdornment,
	TextField,
	Checkbox,
	Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
	signOut,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
} from "firebase/auth";
// components
import Iconify from "../../../components/iconify";

import { auth } from "../../../firebase";

// ----------------------------------------------------------------------

export default function LoginForm() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);

	const signIn = async () => {
		try {
			await signInWithEmailAndPassword(auth, email, password).then(() =>
				navigate("/", { replace: true })
			);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<Stack spacing={3}>
				<TextField
					name="email"
					label="Email address"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>

				<TextField
					name="password"
					label="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					type={showPassword ? "text" : "password"}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									edge="end"
								>
									<Iconify
										icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ my: 2 }}
			>
				{/* <Box>
					<Checkbox name="remember" label="Remember me" />
					<Typography>Remember me</Typography>
				</Box> */}
				{/* <Link variant="subtitle2" underline="hover">
					Forgot password?
				</Link> */}
			</Stack>

			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				onClick={signIn}
			>
				Login
			</LoadingButton>
		</>
	);
}
