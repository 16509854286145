// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
	<SvgColor
		src={`/assets/icons/navbar/${name}.svg`}
		sx={{ width: 1, height: 1 }}
	/>
);

const navConfig = [
	{
		title: "copilots",
		path: "/copilots",
		icon: icon("ic_facebook"),
	},
	{
		title: "dashboard",
		path: "/app",
		icon: icon("ic_analytics"),
	},
	// {
	// 	title: "facebook integration",
	// 	path: "/products",
	// 	icon: icon("ic_facebook"),
	// },
	{
		title: "facebook integration",
		path: "/facebook-integration",
		icon: icon("ic_facebook"),
	},
	{
		title: "telegram integration",
		path: "/telegram-integration",
		icon: icon("ic_facebook"),
	},
	// {
	// 	title: "login",
	// 	path: "/login",
	// 	icon: icon("ic_lock"),
	// },
	// {
	// 	title: "Not found",
	// 	path: "/404",
	// 	icon: icon("ic_disabled"),
	// },
];

export default navConfig;
