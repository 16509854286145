import { faker } from "@faker-js/faker";
import { sample } from "lodash";

// ----------------------------------------------------------------------

const users = [...Array(12)].map((_, index) => ({
	id: faker.datatype.string(),
	name: faker.name.fullName(),
	isSubscribed: faker.datatype.boolean(),
}));

// const users = {
// 	facebookUserId: faker.datatype.string(10),
// 	pages: [...Array(2)].map((_, index) => ({
// 		id: faker.datatype.string(),
// 		name: faker.name.fullName(),
// 		isSubscribed: faker.datatype.boolean(),
// 	})),
// };

export default users;
